import './RadioField.css';
import { FC, PropsWithChildren, ReactNode, useContext, useEffect, useMemo } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { FormOptionsContext } from 'src/components/control/Form';
import { FormControl } from 'src/components/control/FormControl';
import RadioGroup from 'src/components/styled-radix/RadioGroup';
import { useI18n } from 'src/lib/i18n';

// [!] this component hacks react-hook-form

export const RadioField: FC<
  PropsWithChildren<{
    name: string;
    id?: string;
    label?: ReactNode;
    items: { [key: string]: string | { label: string; disabled?: boolean; width?: 'full' | 'half' | 'auto' } };
    bordered?: boolean;
    options?: RegisterOptions<FieldValues, string>;
  }>
> = ({ name, id = name, label, items, bordered = true, options, children }) => {
  const { i18n } = useI18n();
  const { register, watch, setValue } = useFormContext<FieldValues>();
  const { readOnly } = useContext(FormOptionsContext);
  const initialValue = useMemo(() => watch(name), [name]);
  const normalizedItems = Object.fromEntries(
    Object.keys(items).map((key) => {
      if (typeof items[key] == 'string') {
        return [key, { label: items[key], disabled: false, width: bordered ? 'full' : 'auto' }];
      } else {
        return [
          key,
          {
            label: items[key].label,
            disabled: items[key].disabled || false,
            width: items[key].width || 'full',
          },
        ];
      }
    }),
  );

  useEffect(() => {
    register(name, options);
  }, [name, options, register]);

  return (
    <fieldset className="radio-field">
      <FormControl
        id={id}
        name={name}
        required={options?.required ? true : false}
        label={label || i18n.t(`attributes.${name}`)}
      >
        <RadioGroup.Root
          id={id}
          aria-readonly={readOnly}
          defaultValue={initialValue}
          value={watch(name)}
          onValueChange={(value) =>
            setValue(name, value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            })
          }
          disabled={options?.disabled}
        >
          {Object.keys(normalizedItems).map((key) => (
            <label
              key={key}
              htmlFor={`${id}-${key}`}
              className={`radio-field-item-label ${normalizedItems[key].width}${bordered ? ' bordered' : ''}`}
            >
              <RadioGroup.Item value={key} id={`${id}-${key}`} disabled={normalizedItems[key].disabled}>
                <RadioGroup.Indicator />
              </RadioGroup.Item>
              {normalizedItems[key].label}
            </label>
          ))}
        </RadioGroup.Root>
        {children}
        <ErrorMessage name={name} />
      </FormControl>
    </fieldset>
  );
};
