import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { onOpenTicket } from 'src/actions/ticket';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { HiddenField } from 'src/components/control/HiddenField';
import { MultipleMediaFilesField } from 'src/components/control/MultipleMediaFilesField';
import { TextField } from 'src/components/control/TextField';
import { ToDoAlert } from 'src/components/features/ToDoAlert';
import { Icon } from 'src/components/primitives/Icon';
import { useCurrentStatus, usePermission } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { Text } from 'src/components/primitives/Text';
import { ACCEPT_IMAGE } from 'src/lib/uploadFile';
import { Account, AccountSetting } from 'src/models/v1/account';
import { validateMaxLengthOption } from 'src/lib/validation';
import { FormLabel } from 'src/components/control/FormLabel';
import { RadioField } from 'src/components/control/RadioField';
import { StepIndicator } from 'src/components/features/StepIndicator';

export const AskForm: FC<{ account: Account; setting: AccountSetting }> = ({ account, setting }) => {
  const { i18n } = useI18n();
  const navigate = useNavigate();
  const { me } = useCurrentStatus();
  const { permit: permitTicket } = usePermission('ticket');
  const procedures = useMemo(
    () =>
      Object.fromEntries(
        ['undefined', 'shipping', 'in_store_drop_off', 'on_site_pickup', 'on_site_repair'].map((procedure) => [
          procedure,
          {
            label: i18n.t(`enum.procedure.${procedure}`),
            width: procedure == 'undefined' ? 'full' : ('half' as 'full' | 'half'),
            disabled: !(
              procedure == 'undefined' || (account.business_profile!.support_types as Array<string>).includes(procedure)
            ),
          },
        ]),
      ),
    [account.business_profile!.support_types],
  );
  const isDisabled =
    !setting.email_verified || account.gid == me!.gid || !account.business_profile?.accept_repair_request;
  return (
    <Form
      onSubmit={onOpenTicket({})}
      defaultValues={{
        ticket: {
          repairer: { gid: account.gid },
          procedure: `undefined`,
          repair_request: {},
        },
      }}
      disabled={isDisabled}
      mode="all"
    >
      {!setting.email_verified && (
        <ToDoAlert variant="warning" embbed>
          <ToDoAlert.Body>
            <ToDoAlert.Heading>{i18n.t('label.please_verify_email')}</ToDoAlert.Heading>
            <ToDoAlert.Description>{i18n.t('guide.please_verify_email_to_send_repair_request')}</ToDoAlert.Description>
          </ToDoAlert.Body>
          <ToDoAlert.Button role="link" type="button" onClick={() => navigate('/settings/account')}>
            {i18n.t('action.check_account_setting')}
          </ToDoAlert.Button>
        </ToDoAlert>
      )}
      <HiddenField name="ticket.repairer.gid" />
      <RadioField name="ticket.procedure" items={procedures} options={{ required: true, disabled: isDisabled }} />
      <MultipleMediaFilesField
        name={'ticket.repair_request.photo_media_files'}
        mediaFiles={[]}
        options={{ required: true }} /* TODO: implement maxLength validation */
        max={8}
        accept={ACCEPT_IMAGE}
      />
      <TextField
        name={'ticket.repair_request.body'}
        options={{
          required: true,
          maxLength: validateMaxLengthOption({ name: 'ticket.repair_request.body', maxLength: 1000, i18n }),
        }}
      >
        {account.business_profile?.reception_body_annotation && (
          <Text size="medium" color="note">
            {account.business_profile.reception_body_annotation}
          </Text>
        )}
      </TextField>
      <FormLabel>{i18n.t('label.general_ticket_steps')}</FormLabel>
      <StepIndicator direction="column">
        <StepIndicator.Item active>{i18n.t('label.ticket_steps.consultation')}</StepIndicator.Item>
        <StepIndicator.Item>{i18n.t('label.ticket_steps.quotation')}</StepIndicator.Item>
        <StepIndicator.Item>{i18n.t('label.ticket_steps.payment')}</StepIndicator.Item>
        <StepIndicator.Item>{i18n.t('label.ticket_steps.processing')}</StepIndicator.Item>
        <StepIndicator.Item>{i18n.t('label.ticket_steps.delivery')}</StepIndicator.Item>
      </StepIndicator>
      {account.business_profile?.notes && (
        <>
          <FormLabel>{i18n.t('label.notes_on_requests_and_consultations')}</FormLabel>
          <Text size="medium" color="note">
            {account.business_profile?.notes}
          </Text>
        </>
      )}
      <FormLabel>{i18n.t('label.cancellation_policy')}</FormLabel>
      <Text size="medium" color="note">
        {account.business_profile?.cancellation_policy}
      </Text>
      <FormLabel>{i18n.t('label.about_consultation_and_requests')}</FormLabel>
      <Text size="medium" color="note">
        {i18n.t('annotations.ticket.about_consultation_and_requests')}
      </Text>
      <FormButton disabled={!permitTicket} variant="primary-black" data-event-key="submit-repair-request">
        <Icon name="send" />
        {i18n.t('action.submit_repair_request')}
      </FormButton>
    </Form>
  );
};
