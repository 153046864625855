import './CheckField.css';
import { FC, KeyboardEventHandler, PropsWithChildren, useCallback, useContext } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { AriaAttributesContext } from 'src/lib/a11y';
import { Icon } from 'src/components/primitives/Icon';

export const CheckField: FC<
  PropsWithChildren<{
    name?: string;
    bordered?: boolean;
    options?: RegisterOptions<FieldValues, string>;
  }>
> = ({ name = 'confirmation', bordered = true, children, options }) => {
  const { register, watch, setValue } = useFormContext<FieldValues>();
  const a11yAttributes = useContext(AriaAttributesContext);

  const handleKeyDown: KeyboardEventHandler<HTMLLabelElement> = useCallback(
    (event) => {
      if (options?.disabled) {
        return;
      }
      if (event.code == 'Space' || event.code == 'Enter') {
        setValue(name, !watch(name), { shouldValidate: true });
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [setValue, watch],
  );
  return (
    <label
      className={`check-field${bordered ? ' bordered' : ''}`}
      role="checkbox"
      htmlFor={name}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <input
        className="check-field-input"
        type="checkbox"
        id={name}
        {...register(name, { ...options })}
        {...a11yAttributes}
      />
      <Icon name="check_box_outline_blank-fill" className="check-field-icon-blank" />
      <Icon name="check_box-fill" className="check-field-icon-checked" />
      {children}
    </label>
  );
};
