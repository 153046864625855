import './RadioGroup.css';

import { forwardRef, ForwardRefExoticComponent } from 'react';

import * as RadioGroup from '@radix-ui/react-radio-group';

const Root: ForwardRefExoticComponent<RadioGroup.RadioGroupProps & React.RefAttributes<HTMLDivElement>> = forwardRef(
  ({ className, ...props }, forwardedRef) => {
    return (
      <RadioGroup.Root
        className={`radio-group-root${className ? ` ${className}` : ''}`}
        {...props}
        ref={forwardedRef}
      />
    );
  },
);

const Item: ForwardRefExoticComponent<RadioGroup.RadioGroupItemProps & React.RefAttributes<HTMLButtonElement>> =
  forwardRef(({ className, ...props }, forwardedRef) => {
    return (
      <RadioGroup.Item
        className={`radio-group-item${className ? ` ${className}` : ''}`}
        {...props}
        ref={forwardedRef}
      />
    );
  });

const Indicator: ForwardRefExoticComponent<
  RadioGroup.RadioGroupIndicatorProps & React.RefAttributes<HTMLButtonElement>
> = forwardRef(({ className, ...props }, forwardedRef) => {
  return (
    <RadioGroup.Indicator
      className={`radio-group-indicator${className ? ` ${className}` : ''}`}
      {...props}
      ref={forwardedRef}
    />
  );
});

export default {
  ...RadioGroup,
  Root,
  Indicator,
  Item,
};
