import './BusinessVerificationDocumentForm.css';

import { FC, useCallback, useId, useState } from 'react';
import { FileSelectorLabel } from 'src/components/control/FileSelectorLabel';
import { FormControl } from 'src/components/control/FormControl';
import { FormLabel } from 'src/components/control/FormLabel';
import { BusinessIssuesView } from 'src/components/features/business/BusinessIssuesView';
import { Button } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { ImagePreview } from 'src/components/primitives/ImagePreview';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import RadioGroup from 'src/components/styled-radix/RadioGroup';
import { checkInVerificationDocumentUploadingProgress, VerificationMethod } from 'src/lib/business';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { uploadVerificationDocument } from 'src/lib/uploadFile';
import { BusinessVerificationDocument } from 'src/models/v1/business';

export const BusinessVerificationDocumentForm: FC<{
  documents: Array<BusinessVerificationDocument>;
  onComplete: () => void;
}> = ({ documents, onComplete }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const [method, setMethod] = useState(checkInVerificationDocumentUploadingProgress(documents));
  const [uploadedDocuments, setUploadedDocuments] = useState(documents);
  const isCompleted = checkCompleted(uploadedDocuments);
  const onUpload = useCallback(
    async (image: Blob, kind: BusinessVerificationDocument['kind']) => {
      const doc = await uploadVerificationDocument(me!, kind, image);
      setUploadedDocuments((documents) => [...documents, doc]);
      return doc;
    },
    [me],
  );
  return (
    <>
      <BusinessIssuesView kind="review_verification_documents" className="business-verification-document-issue" />
      <FormControl id={useId()} required={true} label={i18n.t(`attributes.business.verification_document_type`)}>
        <RadioGroup.Root
          defaultValue={method}
          value={method}
          onValueChange={(value) => setMethod(value as VerificationMethod)}
          className="business-verification-document-radio-group"
        >
          <label>
            <RadioGroup.Item value="individual_number_card">
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            {i18n.t('label.verification_document.individual_number_card')}
          </label>
          <label>
            <RadioGroup.Item value="drivers_license">
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            {i18n.t('label.verification_document.drivers_license')}
          </label>
          <label>
            <RadioGroup.Item value="residence_card">
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            {i18n.t('label.verification_document.residence_card')}
          </label>
          <label>
            <RadioGroup.Item value="japanese_passport">
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            {i18n.t('label.verification_document.japanese_passport')}
          </label>
          <label>
            <RadioGroup.Item value="residence_certificate">
              <RadioGroup.Indicator />
            </RadioGroup.Item>
            {i18n.t('label.verification_document.residence_certificate')}
          </label>
        </RadioGroup.Root>
      </FormControl>
      <VStack key={method} align="flex-start" m="none" my="md">
        {(method == 'individual_number_card' || method == 'drivers_license' || method == 'residence_card') && (
          <>
            <FormLabel>{i18n.t('label.verification_document_side.front')}</FormLabel>
            <Text asChild color="note">
              <p>{i18n.t('guide.support_png_or_jpeg')}</p>
            </Text>
            <VerificationDocumentView
              id="verification-document-front"
              key={`${method}_front`}
              kind={`${method}_front`}
              documents={documents}
              onUpload={(image) => onUpload(image, `${method}_front`)}
            />
            <FormLabel>{i18n.t('label.verification_document_side.back')}</FormLabel>
            <VerificationDocumentView
              id="verification-document-back"
              key={`${method}_back`}
              kind={`${method}_back`}
              documents={documents}
              onUpload={(image) => onUpload(image, `${method}_back`)}
            />
          </>
        )}
        {(method == 'japanese_passport' || method == 'residence_certificate') && (
          <>
            <Text asChild color="note">
              <p>{i18n.t('guide.support_png_or_jpeg')}</p>
            </Text>
            <VerificationDocumentView
              id="verification-document-single"
              kind={method}
              documents={documents}
              onUpload={(image) => onUpload(image, method)}
            />
          </>
        )}
      </VStack>
      <Button w="full" onClick={onComplete} disabled={!isCompleted}>
        {i18n.t('action.next')}
      </Button>
    </>
  );
};

const VerificationDocumentView: FC<{
  id: string;
  kind: BusinessVerificationDocument['kind'];
  documents: Array<BusinessVerificationDocument>;
  onUpload: (image: Blob) => Promise<BusinessVerificationDocument>;
}> = ({ id, kind, documents, onUpload }) => {
  const { i18n } = useI18n();
  const [document, setDocument] = useState(documents.find((document) => document.kind == kind));
  return (
    <FileSelectorLabel
      id={id}
      accept="image/png, image/jpeg"
      onUpload={async (image) => setDocument(await onUpload(image))}
      active={typeof document == 'undefined'}
    >
      {document ? (
        document.url ? (
          <ImagePreview src={document.url} />
        ) : (
          <>
            <Icon name="verified" />
            {i18n.t('guide.has_been_uploaded')}
          </>
        )
      ) : (
        <Text color="hint" variant="body">
          {i18n.t('label.upload_image')}
        </Text>
      )}
    </FileSelectorLabel>
  );
};

export const checkCompleted = (documents: Array<BusinessVerificationDocument>) => {
  const uploadedKindList = documents.map(({ kind }) => kind);
  if (
    uploadedKindList.includes('individual_number_card_front') &&
    uploadedKindList.includes('individual_number_card_back')
  ) {
    return true;
  }
  if (uploadedKindList.includes('drivers_license_front') && uploadedKindList.includes('drivers_license_back')) {
    return true;
  }
  if (uploadedKindList.includes('residence_card_front') && uploadedKindList.includes('residence_card_back')) {
    return true;
  }
  if (uploadedKindList.includes('japanese_passport') || uploadedKindList.includes('residence_certificate')) {
    return true;
  }
  return false;
};
